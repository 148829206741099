import { useCallback, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { selectEventId } from '../../../redux/slices/config';
import { selectStreamGoRoom } from '../../../redux/slices/room';
import { StreamingView } from '../../../redux/streamGoApi/enums';
import { useGetPlatformEventDetails, useGetRoom } from '../../Rooms';
import { useIsStreamingModeValid } from '../../StreamingViews';
import { PipPosition, StreamType } from '../enums';
import {
  PinnedView,
  PipView,
  PlaylistVideoView,
  PostrollView,
  PrerollView,
  SideBySideView,
  SingleTrackView,
} from '../views';
import { GridView } from '../views/GridView/GridView';
import { useGetSettings } from './useGetSettings';
import { usePlaylist } from './usePlaylist';

export const useGetStreamingTemplate = (roomId: string) => {
  const eventId = useSelector(selectEventId);
  const { pollInterval, postRollVideoUrl, preRollVideoUrl } = useGetSettings();
  const interval = pollInterval ? Number(pollInterval) : 5000;
  useGetRoom(roomId);
  const video = useRef<HTMLVideoElement>(null);
  const data = useSelector(selectStreamGoRoom);

  const { data: eventData } = useGetPlatformEventDetails(eventId, interval);
  const playlist = eventData?.playlist;

  const streamingView = data?.StreamingView;
  const defaultView = data?.StreamingViewDefault;

  const { isMediaMode, nextPlaylistItem, onPlaying } = usePlaylist({
    playlist,
    eventId,
    defaultView,
    video: video?.current,
  });

  useIsStreamingModeValid({
    streamingView,
    roomId,
    isMediaMode: Boolean(isMediaMode) ?? false,
  });

  const SideBySide = useCallback(
    () => <SideBySideView streamingView={streamingView} />,
    [streamingView],
  );

  const Component = useMemo(() => {
    const videoComponents: Record<StreamingView, () => JSX.Element> = {
      [StreamingView.PreRoll]: () => (
        <PrerollView locale="en" webcamGoPreRollVideoUrl={preRollVideoUrl} />
      ),
      [StreamingView.PostRoll]: () => (
        <PostrollView locale="en" webcamGoPostRollVideoUrl={postRollVideoUrl} />
      ),
      [StreamingView.Unset]: () => (
        <SingleTrackView streamType={StreamType.Webcam} />
      ),
      [StreamingView.WebCamsActiveSpeaker]: () => (
        <SingleTrackView streamType={StreamType.Webcam} />
      ),
      [StreamingView.WebCamsActiveSpeakerPinned]: () => (
        <PinnedView streamType={StreamType.Webcam} />
      ),
      [StreamingView.WebCamsGrid]: () => <GridView />,
      [StreamingView.ScreenshareWebcamSplit11Wrsl]: SideBySide,
      [StreamingView.ScreenshareWebcamSplit169Wrsl]: SideBySide,
      [StreamingView.ScreenshareWebCamSplit5050Wrsl]: SideBySide,
      [StreamingView.ScreenshareWebCamSplit8020Wrsl]: SideBySide,
      [StreamingView.SlidesWebcamSplit11Wrsl]: SideBySide,
      [StreamingView.SlidesWebcamSplit169Wrsl]: SideBySide,
      [StreamingView.SlidesWebCamSplit5050Wrsl]: SideBySide,
      [StreamingView.SlidesWebCamSplit8020Wrsl]: SideBySide,
      [StreamingView.FullScreenSlides]: () => (
        <SingleTrackView streamType={StreamType.Slides} />
      ),
      [StreamingView.FullScreenSlidesNoSound]: () => (
        <SingleTrackView hasSound={false} streamType={StreamType.Slides} />
      ),
      [StreamingView.SlidesPinned]: () => (
        <PinnedView streamType={StreamType.Slides} />
      ),
      [StreamingView.FullScreenScreenShare]: () => (
        <SingleTrackView streamType={StreamType.Screenshare} />
      ),
      [StreamingView.ScreenSharePinned]: () => (
        <PinnedView streamType={StreamType.Screenshare} />
      ),
      [StreamingView.SlidesWebCamPipTopRight]: () => (
        <PipView position={PipPosition.Top} streamType={StreamType.Slides} />
      ),
      [StreamingView.SlidesWebCamPipBottomRight]: () => (
        <PipView position={PipPosition.Bottom} streamType={StreamType.Slides} />
      ),
      [StreamingView.ScreenshareWebCamPipTopRight]: () => (
        <PipView
          position={PipPosition.Top}
          streamType={StreamType.Screenshare}
        />
      ),
      [StreamingView.ScreenshareWebCamPipBottomRight]: () => (
        <PipView
          position={PipPosition.Bottom}
          streamType={StreamType.Screenshare}
        />
      ),
    };
    return (
      videoComponents[streamingView ?? StreamingView.WebCamsActiveSpeaker] ||
      (() => <></>)
    );
  }, [SideBySide, streamingView, preRollVideoUrl, postRollVideoUrl]);

  if (!streamingView) {
    return null;
  }

  if (
    isMediaMode &&
    playlist &&
    ![StreamingView.PostRoll, StreamingView.PreRoll].includes(streamingView)
  ) {
    return (
      <PlaylistVideoView
        isMediaMode={isMediaMode}
        nextPlaylistItem={nextPlaylistItem}
        onPlaying={onPlaying}
        playlist={playlist}
        video={video}
      />
    );
  }

  return <Component />;
};

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-alert */
import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadUserChoices } from '@livekit/components-core';
import { LocalUserChoices } from '@livekit/components-react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { useCheckCameraError } from '../../hooks/useCheckCameraError';
import { selectLiveKitToken } from '../../redux/slices/auth';
import {
  selectDisplayName,
  selectHideLoginButton,
  selectQueryString,
  selectRoomName,
  setRoomName,
} from '../../redux/slices/room';
import { PreJoin } from '../LiveKit/PreJoin';
import { ActiveRoom } from './ActiveRoom';
import { IUserConfig } from './types';

export const RoomContainer: any = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  height: '100%',
  alignItems: 'center',
  flexDirection: 'column',
});

export const ExtraButtonContainer: any = styled(Box)({
  top: -32,
  position: 'relative',
});

const preJoinDefaults = {
  username: '',
  videoEnabled: true,
  audioEnabled: false,
};

const userConfigDefaults: IUserConfig = {
  hasBackgroundBlur: false,
};

export const Room = () => {
  const local = loadUserChoices();
  const roomName = useSelector(selectRoomName) ?? '';
  const displayName = useSelector(selectDisplayName) ?? '';
  const hideLoginButton = useSelector(selectHideLoginButton) ?? '';
  const { video } = useSelector(selectQueryString);
  const dispatch = useDispatch();
  const token = useSelector(selectLiveKitToken);
  const [hasError, setHasError] = useState(false);
  const Error = useCheckCameraError(hasError);

  const [preJoinChoices, setPreJoinChoices] = useState<
    LocalUserChoices | undefined
  >(undefined);
  const [userConfig, setUserConfig] = useState<IUserConfig>(userConfigDefaults);

  const handlePreJoinSubmit = useCallback(
    (values: LocalUserChoices, config: IUserConfig) => {
      setPreJoinChoices(values);
      setUserConfig(config);
      dispatch(setRoomName(roomName as string));
    },
    [dispatch, roomName],
  );

  const onPreJoinError = useCallback(() => {
    setHasError(true);
  }, []);

  return (
    <main data-lk-theme="default">
      {roomName &&
      ((token && local) || (!Array.isArray(roomName) && preJoinChoices)) ? (
        <ActiveRoom
          roomName={roomName}
          userChoices={preJoinChoices ?? local}
          userConfig={userConfig}
        />
      ) : (
        <RoomContainer>
          <PreJoin
            defaults={{
              ...preJoinDefaults,
              audioEnabled: displayName.toLowerCase().startsWith('sg_vm'),
              videoEnabled: video ?? true,
              username: displayName,
            }}
            disableName={Boolean(displayName)}
            disableVideo={!video}
            hideLogin={Boolean(hideLoginButton)}
            joinLabel="Join"
            onError={onPreJoinError}
            onSubmit={handlePreJoinSubmit}
            persistUserChoices={!displayName}
          />
        </RoomContainer>
      )}
      {Error}
    </main>
  );
};

import { useCallback, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Participant } from 'livekit-client';
import AccountBox from '@mui/icons-material/AccountBox';
import CircularProgress from '@mui/material/CircularProgress';
import { selectIsModerator } from '../../../redux/slices/auth';
import {
  IConferenceParticipant,
  useUploadImageMutation,
  useUploadPresenterImageMutation,
} from '../../../redux/streamGoApi';
import { getBase64StringForUpload } from '../../DynamicLayout/helpers';
import { ActionDialog } from '../../Form';

interface IPresenterImageIcon {
  conferenceParticipant?: IConferenceParticipant;
  participant?: Participant;
  roomId: string;
}

export const PresenterImageIcon = ({
  conferenceParticipant,
  participant,
  roomId,
}: IPresenterImageIcon) => {
  const isModerator = useSelector(selectIsModerator);
  const isEditAllowed = participant?.isLocal || isModerator;
  const [open, setOpen] = useState(false);
  const [uploadPresenterImage, { isLoading }] =
    useUploadPresenterImageMutation();
  const [uploadImageDelete, { isLoading: isLoadingDelete }] =
    useUploadImageMutation();
  const fileInput = useRef<HTMLInputElement>(null);

  const handleOpenClose = useCallback(
    (openModal: boolean) => () => {
      if (isEditAllowed) {
        setOpen(openModal);
      }
    },
    [isEditAllowed],
  );

  const uploadImage = useCallback(
    async (file: FileList) => {
      const BackgroundBase64 = await getBase64StringForUpload(file);

      if (fileInput.current?.value) {
        fileInput.current.value = '';
      }

      if (participant?.sid && BackgroundBase64 && isEditAllowed) {
        setOpen(false);
        await uploadPresenterImage({
          roomId,
          Participant: {
            PresenterImageB64: BackgroundBase64,
            UserId: participant?.sid,
          },
        });
      }
    },
    [isEditAllowed, participant?.sid, roomId, uploadPresenterImage],
  );

  const deleteImage = useCallback(async () => {
    if (participant?.sid && isEditAllowed) {
      setOpen(false);
      await uploadImageDelete({
        roomId,
        Participant: {
          PresenterImageB64: null,
          UserId: participant?.sid,
        },
      });
    }
  }, [isEditAllowed, participant?.sid, roomId, uploadImageDelete]);

  const handleFileInput = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        uploadImage(e.target.files);
      }
    },
    [uploadImage],
  );

  const handleYesClick = useCallback(() => {
    if (isEditAllowed) {
      fileInput?.current?.click?.();
    }
  }, [isEditAllowed]);

  const isSgVmParticipant = participant?.identity
    .toLowerCase()
    .startsWith('sg_vm');

  return (
    <>
      <input
        onChange={handleFileInput}
        ref={fileInput}
        style={{ display: 'none' }}
        type="file"
      />
      {!isSgVmParticipant && !(isLoading || isLoadingDelete) ? (
        <>
          {conferenceParticipant?.ParticipantImgUrl ? (
            <AccountBox
              className={`icon-on ${!isEditAllowed ? 'disabled' : ''}`}
              onClick={handleOpenClose(true)}
            />
          ) : (
            <AccountBox
              className={`icon-off ${!isEditAllowed ? 'disabled' : ''}`}
              onClick={handleYesClick}
            />
          )}
        </>
      ) : null}
      {!isSgVmParticipant && (isLoading || isLoadingDelete) ? (
        <CircularProgress size={24} />
      ) : null}
      <ActionDialog
        actionButtons={[
          {
            color: 'error',
            onClick: deleteImage,
            buttonText: 'Delete image',
          },
          {
            color: 'primary',
            onClick: handleYesClick,
            buttonText: 'Replace image',
          },
        ]}
        onCancel={handleOpenClose(false)}
        open={open}
        title={'Presenter image options'}
      >
        <>What would you like to do?</>
      </ActionDialog>
    </>
  );
};

/* eslint-disable max-nested-callbacks */
/* eslint-disable no-plusplus */
import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectBaseUrl } from '../redux/slices/config';
import { selectStreamGoRoom } from '../redux/slices/room';
import { useUploadSpeedTestResultsMutation } from '../redux/streamGoApi';
import { checkDownloadSpeed, checkUploadSpeed } from './networkSpeedTest';

const getNetworkDownloadSpeed = async () => {
  const baseUrl =
    'https://cdn-aws.s3.eu-west-1.amazonaws.com/webcamGo/Sample-jpg-image-500kb.jpg';
  const fileSizeInBytes = 512017;
  const speed = await checkDownloadSpeed(baseUrl, fileSizeInBytes);
  return speed;
};

const getNetworkUploadSpeed = async (baseUrl: string) => {
  const { hostname } = new URL(baseUrl);
  const options = {
    hostname,
    port: 443,
    path: '/',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const fileSizeInBytes = 250000;
  const speed = await checkUploadSpeed(options, fileSizeInBytes);
  return speed;
};

interface ISpeedTestHookResult {
  isRunning: boolean;
  speedDownload: number;
  speedUpload: number;
  startTest: (iterations?: number) => Promise<void>;
}

interface IUseSpeedTest {
  participantId?: string;
}

export function useSpeedTest({
  participantId,
}: IUseSpeedTest): ISpeedTestHookResult {
  const baseUrl = useSelector(selectBaseUrl);
  const roomDetails = useSelector(selectStreamGoRoom);
  const [uploadSpeedTestMutation] = useUploadSpeedTestResultsMutation();
  const [isRunning, setIsRunning] = useState(false);
  const [speedDownload, setSpeedDownload] = useState(0);
  const [speedUpload, setSpeedUpload] = useState(0);

  const postSpeedTest = useCallback(
    (downloadSpeed: number, uploadSpeed: number) => {
      if (participantId && roomDetails?.RoomName && roomDetails?.ConferenceId) {
        const speedTestResults = {
          roomId: roomDetails?.RoomName,
          conference: {
            confId: roomDetails?.ConferenceId,
          },
          Participant: {
            SpeedTest: {
              DownloadSpeed: downloadSpeed,
              UploadSpeed: uploadSpeed,
            },
            UserId: participantId,
          },
        };

        uploadSpeedTestMutation(speedTestResults);
      }
    },
    [
      participantId,
      roomDetails?.ConferenceId,
      roomDetails?.RoomName,
      uploadSpeedTestMutation,
    ],
  );

  const startTest = useCallback(
    async (iterations = 7) => {
      try {
        setIsRunning(true);
        let currentAverageDownload = 0;
        let totalSpeedDownloadSpeed = 0;
        setSpeedDownload(currentAverageDownload);

        for (let i = 0; i < iterations; i++) {
          // eslint-disable-next-line no-await-in-loop
          const speed = await getNetworkDownloadSpeed();
          totalSpeedDownloadSpeed += Number(speed.mbps);
          currentAverageDownload = totalSpeedDownloadSpeed / (i + 1);
          setSpeedDownload(currentAverageDownload);
        }

        let totalSpeedUploadSpeed = 0;
        let currentAverageUpload = 0;
        setSpeedUpload(currentAverageUpload);

        for (let i = 0; i < iterations; i++) {
          // eslint-disable-next-line no-await-in-loop
          const speed = await getNetworkUploadSpeed(baseUrl);
          totalSpeedUploadSpeed += Number(speed.mbps);
          currentAverageUpload = totalSpeedUploadSpeed / (i + 1);
          setSpeedUpload(currentAverageUpload);
        }

        postSpeedTest(currentAverageDownload, currentAverageUpload);
      } catch (err) {
        console.error(err);
      } finally {
        setTimeout(() => {
          setIsRunning(false);
        }, 5000);
      }
    },
    [baseUrl, postSpeedTest],
  );

  return {
    isRunning,
    speedDownload,
    speedUpload,
    startTest,
  };
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { ParticipantTileMinimal } from '../LiveKit/ParticipantTileMinimal';

export const FullscreenVideo: any = styled('video')({
  width: '100vw',
  height: '100vh',
  objectFit: 'cover',
  position: 'fixed',
  top: 0,
  left: 0,
});

export const FullScreen: any = styled(Box)({
  width: '100vw',
  height: '100vh',
  objectFit: 'contain',
});

export const FullScreenVideoTrack: any = styled(ParticipantTileMinimal)({
  width: '100vw',
  height: '100vh',
  objectFit: 'contain',
  background: 'transparent',
});

export const VideoContainer: any = styled('div')({
  display: 'flex',
  position: 'relative',
  objectFit: 'contain',
});

import { TrackReference } from '@livekit/components-react';
import { css, styled } from '@mui/material/styles';
import { ParticipantTileMinimal } from '../../../LiveKit/ParticipantTileMinimal';
import { RoomAudioRenderer } from '../../../LiveKit/RoomAudioRenderer';
import { PipPosition, StreamType } from '../../enums';
import { useGetTracks } from '../../hooks';
import { useSetLiveView } from '../../hooks/useSetLiveView';
import { FullScreenVideoTrack } from '../../Styled';

interface IPipView {
  position: PipPosition;
  streamType: StreamType;
}

interface IPipVideoTrack {
  position: PipPosition;
}

const PipVideoTrack = styled('div')<IPipVideoTrack>(
  ({ position, theme }) => css`
    width: 20%;
    position: fixed !important;
    height: auto;
    max-height: 30%;
    right: ${theme.spacing(4)};
    top: ${position === 'top' ? theme.spacing(4) : 'auto'};
    bottom: ${position === 'bottom' ? theme.spacing(4) : 'auto'};
    z-index: 1000;
  `,
);
export const PipView = ({ position, streamType }: IPipView) => {
  const { tracks } = useGetTracks();
  const webcamTrack = tracks[StreamType.Webcam];
  const videoTrack = tracks[streamType];

  useSetLiveView({
    component: 'pipView',
    videoTracks: [webcamTrack],
  });

  if (!videoTrack) {
    return null;
  }
  return (
    <>
      <FullScreenVideoTrack trackRef={videoTrack as TrackReference} />
      {webcamTrack ? (
        <PipVideoTrack position={position}>
          <ParticipantTileMinimal
            className="videoCam"
            trackRef={webcamTrack as TrackReference}
          />
        </PipVideoTrack>
      ) : null}
      <RoomAudioRenderer />
    </>
  );
};

import { useRef } from 'react';
import { TrackReference } from '@livekit/components-core';
import {
  SideBySideStreamingViews,
  StreamingView,
} from '../../../../redux/streamGoApi/enums';
import { NameTag } from '../../../DynamicLayout/StreamingView';
import { ParticipantTileMinimal } from '../../../LiveKit/ParticipantTileMinimal';
import { RoomAudioRenderer } from '../../../LiveKit/RoomAudioRenderer';
import { useGetTracks } from '../../hooks';
import { useSetLiveView } from '../../hooks/useSetLiveView';
import { VideoContainer } from '../../Styled';
import { SingleTrackView } from '../SingleTrackView';
import { sideBySideConfig } from './config';
import {
  SideBySideViewVideoContainer,
  SideBySideViewVideoOrImageTrack,
  SideBySideViewVideoTrack,
} from './Styled';
import { calculateVideoWidths } from './utils';

interface ISideBySideView {
  streamingView?: StreamingView;
}

export const SideBySideView = ({ streamingView }: ISideBySideView) => {
  const ref = useRef<HTMLVideoElement>(null);
  const { tracks } = useGetTracks();

  const { inline, leftStream, ratio, rightStream, rightWidth } =
    sideBySideConfig[streamingView as unknown as SideBySideStreamingViews];

  const leftTrack = tracks[leftStream] as TrackReference;
  const rightTrack = tracks[rightStream] as TrackReference;

  useSetLiveView({
    component: 'sideBySideView',
    videoTracks: [rightTrack],
  });

  if (!leftTrack && !rightTrack) {
    return <></>;
  }

  if (leftTrack && !rightTrack) {
    return <SingleTrackView disableSetLive={true} streamType={leftStream} />;
  }

  if (!leftTrack && rightTrack) {
    return <SingleTrackView disableSetLive={true} streamType={rightStream} />;
  }

  const rightW = Number(
    inline
      ? calculateVideoWidths(
          ratio,
          ref.current?.clientWidth
            ? {
                width: ref.current?.clientWidth,
                height: ref.current?.clientHeight,
              }
            : undefined,
        ).right
      : rightWidth,
  );

  return (
    <>
      <SideBySideViewVideoContainer className="video-cover">
        <div>
          <SideBySideViewVideoTrack
            ref={ref}
            trackRef={leftTrack}
            width={rightW ? 100 - rightW : undefined}
          />
        </div>
        <VideoContainer>
          <SideBySideViewVideoOrImageTrack ratio={ratio} width={rightW}>
            <ParticipantTileMinimal
              className="videoCam"
              trackRef={rightTrack}
            />
          </SideBySideViewVideoOrImageTrack>
          <NameTag participant={rightTrack.participant} />
        </VideoContainer>
      </SideBySideViewVideoContainer>
      <RoomAudioRenderer />
    </>
  );
};

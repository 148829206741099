import React, { useCallback, useRef } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import UploadIcon from '@mui/icons-material/Upload';

import CircularProgress from '@mui/material/CircularProgress';
import { DynamicImage, IconButtonStyled } from './styled';

interface IBackgroundUploader {
  backgroundUrl?: string;
  loading: boolean;
  setBackgroundUrl: (background: string) => void;
  uploadImage: (file: FileList) => void;
}

export const BackgroundUploader = ({
  backgroundUrl,
  loading,
  setBackgroundUrl,
  uploadImage,
}: IBackgroundUploader) => {
  const fileInput = useRef<HTMLInputElement>(null);

  const handleFileInput = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        uploadImage(e.target.files);
      }
    },
    [uploadImage],
  );

  const handleYesClick = useCallback(() => {
    fileInput?.current?.click?.();
  }, []);

  const handleNoClick = useCallback(() => {
    setBackgroundUrl('');
  }, [setBackgroundUrl]);

  return (
    <>
      <div style={{ zIndex: 1 }}>
        <input
          onChange={handleFileInput}
          ref={fileInput}
          style={{ display: 'none' }}
          type="file"
        />
        <IconButtonStyled
          className="btnSuccess"
          disabled={loading}
          onClick={handleYesClick}
        >
          {loading ? <CircularProgress size={24} /> : <UploadIcon />}
        </IconButtonStyled>

        {backgroundUrl ? (
          <IconButtonStyled className="btnDanger" onClick={handleNoClick}>
            <CloseIcon />
          </IconButtonStyled>
        ) : null}
      </div>
      {backgroundUrl ? (
        <div>
          <DynamicImage alt="backgroundImg" src={backgroundUrl} />{' '}
        </div>
      ) : null}
    </>
  );
};

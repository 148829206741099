/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  LocalUserChoices,
  formatChatMessageLinks,
} from '@livekit/components-react';
import CircleIcon from '@mui/icons-material/Circle';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import {
  selectLiveKitRoomProps,
  selectLiveKitToken,
  setLiveKitRoomProps,
} from '../../redux/slices/auth';
import { selectQueryString } from '../../redux/slices/room';
import { Loading } from '../Form';
import { VideoConference } from '../LiveKit';
import { SettingsMenu } from '../Settings';
import { useGetRoom } from './hooks';
import { CurrentUserLive } from './LiveKitRoomStreamGo';
import { IUserConfig } from './types';

const VideoConferenceStyled = styled(Box, {
  shouldForwardProp: props => props !== 'currentUserLive',
})<{
  currentUserLive: boolean;
}>(
  ({ currentUserLive }) => `
    width: 100%;
    height: 100%;
    
    .lk-control-bar {
      background-color: ${currentUserLive ? 'red' : ''}
    }
    `,
);

interface IActiveRoomProps {
  region?: string;
  roomName: string;
  userChoices: LocalUserChoices;
  userConfig: IUserConfig;
}

export const ActiveRoom = ({
  roomName,
  userChoices,
  userConfig,
}: IActiveRoomProps) => {
  useGetRoom(roomName);
  const dispatch = useDispatch();
  const token = useSelector(selectLiveKitToken);
  const liveKeyRoomProps = useSelector(selectLiveKitRoomProps);
  const { hq } = useSelector(selectQueryString);
  const [currentUserLive, setCurrentUserLive] = useState(false);

  useEffect(() => {
    if (roomName && userChoices && userConfig && !liveKeyRoomProps) {
      const liveKitRoomProps = {
        hq,
        roomName,
        userChoices,
        userConfig,
      };

      dispatch(setLiveKitRoomProps(liveKitRoomProps));
    }
  }, [dispatch, hq, liveKeyRoomProps, roomName, userChoices, userConfig]);

  return (
    <VideoConferenceStyled currentUserLive={currentUserLive}>
      <>
        {token ? (
          <>
            <VideoConference
              chatMessageFormatter={formatChatMessageLinks}
              setCurrentUserLive={setCurrentUserLive}
              SettingsComponent={SettingsMenu}
              userConfig={userConfig}
            />
            {currentUserLive ? (
              <CurrentUserLive>
                <CircleIcon /> CAM LIVE
              </CurrentUserLive>
            ) : null}
          </>
        ) : (
          <Loading />
        )}
      </>
    </VideoConferenceStyled>
  );
};

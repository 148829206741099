import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {
  selectShowLoginPage,
  setIsModerator,
  setShowLoginPage,
} from '../../redux/slices/auth';
import { RootState } from '../../redux/store';
import { useLoginMutation } from '../../redux/streamGoApi';
import { Loading, Logo, SgBox, SgContainer, SgTextField } from '../Form';
import { Page } from './enums';

interface IFormData {
  password: string;
  username: string;
}

interface ILogin {
  roomId: string;
  setPage: (page: Page) => void;
}

export const Login = ({ roomId, setPage }: ILogin) => {
  const dispatch = useDispatch();
  const showLoginPage = useSelector(selectShowLoginPage);
  const [login, { isLoading }] = useLoginMutation();
  const { token } = useSelector((state: RootState) => state.auth);

  const handleSetPage = useCallback(() => {
    if (showLoginPage) {
      dispatch(setShowLoginPage(false));
    } else {
      setPage(Page.PreLogin);
    }
  }, [dispatch, setPage, showLoginPage]);

  const [formData, setFormData] = useState<IFormData>({
    username: '',
    password: '',
  });

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      [e.target.name]: e.target.value,
    }));
  }, []);

  const handleSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      const { password, username } = formData;
      login({
        username,
        password,
        roomId,
      });
      dispatch(setIsModerator(true));
      dispatch(setShowLoginPage(false));
    },
    [dispatch, formData, login, roomId],
  );

  return (
    <>
      <SgContainer>
        <SgBox>
          <Logo />
          <Typography gutterBottom={true} variant="subtitle1">
            Login as a moderator
          </Typography>
          <form onSubmit={handleSubmit}>
            <SgTextField
              autoComplete="name"
              fullWidth={true}
              id="displayName"
              label="Username"
              margin="normal"
              name="username"
              onChange={handleChange}
              required={true}
              value={formData.username}
              variant="outlined"
            />
            <SgTextField
              autoComplete="name"
              fullWidth={true}
              id="password"
              label="Password"
              margin="normal"
              name="password"
              onChange={handleChange}
              required={true}
              type="password"
              value={formData.password}
              variant="outlined"
            />
            <Box display="flex" gap={2} sx={{ mt: 2 }} width="100%">
              <Button
                color="primary"
                sx={{ width: token ? '50%' : '100%' }}
                type="submit"
                variant="contained"
              >
                Login
              </Button>
              {token ? (
                <Button
                  color="secondary"
                  onClick={handleSetPage}
                  sx={{ width: '50%' }}
                  type="button"
                  variant="contained"
                >
                  Cancel
                </Button>
              ) : null}
            </Box>
          </form>
        </SgBox>
      </SgContainer>
      {isLoading ? <Loading /> : null}
    </>
  );
};

/* eslint-disable max-len */
import * as React from 'react';
import { Track } from 'livekit-client';
import {
  TrackReferenceOrPlaceholder,
  getTrackReferenceId,
  isLocal,
} from '@livekit/components-core';
import { AudioTrack, useTracks } from '@livekit/components-react';
import { excludedAudioPresenters } from '../../utils/constants';

export interface IRoomAudioRendererProps {
  muted?: boolean;
  volume?: number;
}

export const isTrackReferenceValid = (
  trackReference: TrackReferenceOrPlaceholder,
) =>
  !excludedAudioPresenters.find(name =>
    trackReference.participant.identity?.toLowerCase().startsWith(name),
  );

export function RoomAudioRenderer({ muted, volume }: IRoomAudioRendererProps) {
  const tracks = useTracks(
    [
      Track.Source.Microphone,
      Track.Source.ScreenShareAudio,
      Track.Source.Unknown,
    ],
    {
      updateOnlyOn: [],
      onlySubscribed: true,
    },
  ).filter(
    ref =>
      isTrackReferenceValid(ref) &&
      !isLocal(ref.participant) &&
      ref.publication.kind === Track.Kind.Audio,
  );

  return (
    <div style={{ display: 'none' }}>
      {tracks.map(trackRef => (
        <AudioTrack
          key={getTrackReferenceId(trackRef)}
          muted={muted}
          trackRef={trackRef}
          volume={volume}
        />
      ))}
    </div>
  );
}

/* eslint-disable no-plusplus */
import React, { useCallback } from 'react';
import { TrackReference } from '@livekit/components-react';
import { styled } from '@mui/material/styles';
import { NameTag } from '../../../DynamicLayout/StreamingView/NameTag';
import { ParticipantTileMinimal } from '../../../LiveKit/ParticipantTileMinimal';
import { RoomAudioRenderer } from '../../../LiveKit/RoomAudioRenderer';
import { useGetRoomStreamingDetails } from '../../../StreamingViews';
import { useGetPinnedPresenters } from '../../hooks/useGetPinnedPresenters';
import { useSetLiveView } from '../../hooks/useSetLiveView';

const GridContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
  width: '100vw',
});

const VideoContainer = styled('div')({
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  width: '100%',
  overflow: 'hidden',
});

const Row = styled('div')<{ totalRows: number; tracks: number }>(
  ({ totalRows, tracks }) => ({
    display: 'flex',
    justifyContent: 'center',
    width: 'auto',
    height:
      tracks === 2 ? 'calc(100vh / 1.77777)' : `calc(100vh / ${totalRows})`,
  }),
);

export const GridView = () => {
  const { connectedVideoTracks } = useGetRoomStreamingDetails();

  const pinnedTracks = useGetPinnedPresenters({
    tracks: connectedVideoTracks,
  });

  const getRows = useCallback((tracks: TrackReference[]) => {
    const numRows = Math.ceil(
      tracks.length / Math.ceil(Math.sqrt(tracks.length)),
    );
    const numPerRow = Math.ceil(tracks.length / numRows);
    const rows: TrackReference[][] = [];

    for (let i = 0; i < numRows; i++) {
      const start = i * numPerRow;
      const end = start + numPerRow;
      rows.push(tracks.slice(start, Math.min(end, tracks.length)));
    }

    return rows;
  }, []);

  const rows = getRows(pinnedTracks);

  useSetLiveView({
    component: 'gridView',
    videoTracks: pinnedTracks,
  });

  return (
    <GridContainer className="video-cover">
      {rows.map((row, index) => (
        <Row key={index} totalRows={rows.length} tracks={pinnedTracks.length}>
          {row.map(videoTrack => (
            <VideoContainer key={videoTrack.participant.sid}>
              <ParticipantTileMinimal
                className="videoCam"
                trackRef={videoTrack}
              />
              <NameTag participant={videoTrack.participant} />
            </VideoContainer>
          ))}
        </Row>
      ))}
      <RoomAudioRenderer />
    </GridContainer>
  );
};

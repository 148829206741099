import * as http from 'http';
import * as https from 'https';

const { Url } = require('url');

const chars =
  "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789~!@#$%^&*()_+`-=[]{}|;':,./<>?";

interface ISpeedTestResult {
  bps: string;
  kbps: string;
  mbps: string;
}

interface IRequestOptions extends https.RequestOptions {
  headers: {
    'Content-Type': string;
  };
  hostname: string;
  method: string;
  path: string;
  port: number;
}

function getProtocol(url: string): typeof http | typeof https {
  const u = new Url(url);
  return u.protocol === 'http:' ? http : https;
}

function generateTestData(sizeInKmb: number): string {
  const iterations: number = sizeInKmb * 1000;
  return Array.from({ length: iterations }, () =>
    chars.charAt(Math.floor(Math.random() * chars.length)),
  ).join('');
}

export function checkDownloadSpeed(
  baseUrl: string,
  fileSizeInBytes: number,
): Promise<ISpeedTestResult> {
  let startTime: number;
  const protocol = getProtocol(baseUrl);
  return new Promise((resolve, reject) => {
    protocol
      .get(`${baseUrl}?v=${Math.random()}`, response => {
        response.once('data', () => {
          startTime = new Date().getTime();
        });

        response.once('end', () => {
          const endTime = new Date().getTime();
          const duration =
            (parseFloat(endTime.toString()) -
              parseFloat(startTime.toString())) /
            1000;
          const bitsLoaded = fileSizeInBytes * 8;
          const bps = (bitsLoaded / duration).toFixed(2);
          const kbps = (Number(bps) / 1000).toFixed(2);
          const mbps = (Number(kbps) / 1000).toFixed(2);
          resolve({ bps, kbps, mbps });
        });
      })
      .on('error', error => {
        console.error(error);
        reject();
      });
  });
}

export function checkUploadSpeed(
  options: IRequestOptions,
  fileSizeInBytes = 100000,
): Promise<ISpeedTestResult> {
  let startTime: number;
  const defaultData = generateTestData(fileSizeInBytes / 1000);
  const data = JSON.stringify({ method: 'wake', defaultData });
  return new Promise((resolve, reject) => {
    const req = https.request(options, res => {
      res.setEncoding('utf8');
      res.on('data', () => {});
      res.on('end', () => {
        const endTime = new Date().getTime();
        const duration = (endTime - startTime) / 1000;
        const bitsLoaded = fileSizeInBytes * 8;
        const bps = (bitsLoaded / duration).toFixed(2);
        const kbps = (Number(bps) / 1000).toFixed(2);
        const mbps = (Number(kbps) / 1000).toFixed(2);
        resolve({ bps, kbps, mbps });
      });
    });
    startTime = new Date().getTime();
    req.on('error', reject);
    req.write(data);
    req.end();
  });
}

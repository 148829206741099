/* eslint-disable max-lines */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useResizeDetector } from 'react-resize-detector';
import { LocalVideoTrack, RoomEvent, Track } from 'livekit-client';
import type {
  MessageDecoder,
  MessageEncoder,
  TrackReferenceOrPlaceholder,
  WidgetState,
} from '@livekit/components-core';
import {
  isEqualTrackRef,
  isTrackReference,
  isWeb,
  log,
} from '@livekit/components-core';
import {
  CarouselLayout,
  Chat,
  ConnectionStateToast,
  ControlBar,
  FocusLayoutContainer,
  GridLayout,
  LayoutContextProvider,
  MessageFormatter,
  RoomAudioRenderer,
  useCreateLayoutContext,
  useLocalParticipant,
  usePinnedTracks,
  useRoomContext,
  useTracks,
} from '@livekit/components-react';
import { BackgroundBlur } from '@livekit/track-processors';
import { useSpeedTest } from '../../hooks/useSpeedTest';
import { selectIsModerator } from '../../redux/slices/auth';
import {
  selectStreamGoRoom,
  selectIsPresentGo,
  selectLiveNowUsers,
  selectAudioOutputDeviceId,
  selectQueryString,
} from '../../redux/slices/room';
import { IUserConfig } from '../Rooms';
import { FocusLayout } from './FocusLayout';
import { ParticipantTile } from './ParticipantTile';
import { getWindowSizeClass } from './utils';
import { WidgetManager } from './WidgetManager';

export interface IVideoConferenceProps
  extends React.HTMLAttributes<HTMLDivElement> {
  /** @alpha */
  SettingsComponent?: (props: any) => JSX.Element;
  chatMessageDecoder?: MessageDecoder;
  chatMessageEncoder?: MessageEncoder;
  chatMessageFormatter?: MessageFormatter;
  setCurrentUserLive: (useLive: boolean) => void;
  userConfig: IUserConfig;
}

export function VideoConference({
  chatMessageDecoder,
  chatMessageEncoder,
  chatMessageFormatter,
  setCurrentUserLive,
  SettingsComponent,
  userConfig,
  ...props
}: IVideoConferenceProps) {
  const room = useRoomContext();
  const [localSid, setLocalSid] = useState<string>();
  const liveNowUsers = useSelector(selectLiveNowUsers);
  const { chat, lowcpumode, screenshare, video } =
    useSelector(selectQueryString);
  const outputDeviceId = useSelector(selectAudioOutputDeviceId);
  const isModerator = useSelector(selectIsModerator);
  const isPresentGo = useSelector(selectIsPresentGo);
  const [hasBackgroundBlur, setHasBackgroundBlur] = useState(
    userConfig.hasBackgroundBlur,
  );
  const sgRoom = useSelector(selectStreamGoRoom);
  const [windowWidth, setWindowWidth] = useState(1000);
  const { ref: sizeRef, width = 1000 } = useResizeDetector();

  useEffect(() => {
    if (outputDeviceId) {
      room.switchActiveDevice('audiooutput', outputDeviceId);
    }
  }, [outputDeviceId]);

  useEffect(() => {
    const clientWidth = sizeRef?.current?.clientWidth;
    if (clientWidth) {
      setWindowWidth(clientWidth);
    }
  }, [width]);

  const [widgetState, setWidgetState] = React.useState<WidgetState>({
    showChat: false,
    unreadMessages: 0,
    showSettings: false,
  });
  const { showChat, showSettings } = widgetState;
  const sideBarOpen = showChat || showSettings;
  const lastAutoFocusedScreenShareTrack =
    React.useRef<TrackReferenceOrPlaceholder | null>(null);

  const { cameraTrack, localParticipant } = useLocalParticipant();

  const localSgParticipant = sgRoom?.Participants.find(
    participant => participant.ParticipantId === localSid,
  );

  const { startTest } = useSpeedTest({
    participantId: localSid,
  });

  useEffect(() => {
    let interval = 0;

    interval = window.setInterval(() => {
      if (localParticipant.sid) {
        setLocalSid(localParticipant.sid);
        clearInterval(interval);
      }
    }, 1000);
  }, []);

  const doSpeedTest = useCallback(() => {
    if (
      localSid &&
      (localSgParticipant?.DownloadSpeed === null ||
        localSgParticipant?.UploadSpeed === null ||
        localSgParticipant?.DownloadSpeed === 0 ||
        localSgParticipant?.UploadSpeed === 0)
    ) {
      startTest(5);
    }
  }, [
    localSid,
    localSgParticipant?.DownloadSpeed,
    localSgParticipant?.UploadSpeed,
  ]);

  useEffect(() => {
    let interval = 0;
    doSpeedTest();

    interval = window.setInterval(() => {
      doSpeedTest();
    }, 30000);

    return () => clearInterval(interval);
  }, [doSpeedTest]);

  useEffect(() => {
    if (localSid) {
      window.setTimeout(() => {
        startTest(5);
      }, 5000);
    }
  }, [localSid]);

  useEffect(() => {
    setCurrentUserLive(
      Boolean(liveNowUsers.find(x => x === localParticipant.sid)),
    );
  }, [localParticipant.sid, liveNowUsers]);

  const filterTracks = (track: TrackReferenceOrPlaceholder) => {
    if (track.participant.isLocal) {
      return true;
    }
    if (track.participant.name?.startsWith('altViewMod')) {
      return false;
    }
    if (lowcpumode) {
      return track.participant.sid === localParticipant.sid;
    }
    if (!isModerator) {
      return !track.participant.identity.toLowerCase().startsWith('sg_vm');
    }
    return true;
  };

  const tracks = useTracks(
    [
      { source: Track.Source.Camera, withPlaceholder: true },
      { source: Track.Source.ScreenShare, withPlaceholder: false },
    ],
    { updateOnlyOn: [RoomEvent.ActiveSpeakersChanged], onlySubscribed: false },
  ).filter(filterTracks);

  useEffect(() => {
    if (cameraTrack?.videoTrack instanceof LocalVideoTrack) {
      const currentProcessor = cameraTrack?.videoTrack?.getProcessor();

      if (hasBackgroundBlur && !currentProcessor) {
        const blur = BackgroundBlur(20, { delegate: 'CPU' });
        cameraTrack?.videoTrack.setProcessor(blur);
      } else if (currentProcessor) {
        cameraTrack.videoTrack.stopProcessor();
      }
    }
  }, [hasBackgroundBlur, cameraTrack?.videoTrack]);

  const widgetUpdate = React.useCallback((state: WidgetState) => {
    log.debug('updating widget state', state);
    setWidgetState(state);
  }, []);

  const layoutContext = useCreateLayoutContext();

  const screenShareTracks = tracks
    .filter(isTrackReference)
    .filter(track => track.publication.source === Track.Source.ScreenShare);

  const focusTrack = usePinnedTracks(layoutContext)?.[0];
  const carouselTracks = tracks.filter(
    track => !isEqualTrackRef(track, focusTrack),
  );

  React.useEffect(() => {
    // If screen share tracks are published, and no pin is set explicitly, auto set the screen share.
    if (
      screenShareTracks.some(track => track.publication.isSubscribed) &&
      lastAutoFocusedScreenShareTrack.current === null
    ) {
      log.debug('Auto set screen share focus:', {
        newScreenShareTrack: screenShareTracks[0],
      });
      layoutContext.pin.dispatch?.({
        msg: 'set_pin',
        trackReference: screenShareTracks[0],
      });
      // eslint-disable-next-line prefer-destructuring
      lastAutoFocusedScreenShareTrack.current = screenShareTracks[0];
    } else if (
      lastAutoFocusedScreenShareTrack.current &&
      !screenShareTracks.some(
        track =>
          track.publication.trackSid ===
          lastAutoFocusedScreenShareTrack.current?.publication?.trackSid,
      )
    ) {
      log.debug('Auto clearing screen share focus.');
      layoutContext.pin.dispatch?.({ msg: 'clear_pin' });
      lastAutoFocusedScreenShareTrack.current = null;
    }
    if (focusTrack && !isTrackReference(focusTrack)) {
      const updatedFocusTrack = tracks.find(
        tr =>
          tr.participant.identity === focusTrack.participant.identity &&
          tr.source === focusTrack.source,
      );
      if (
        updatedFocusTrack !== focusTrack &&
        isTrackReference(updatedFocusTrack)
      ) {
        layoutContext.pin.dispatch?.({
          msg: 'set_pin',
          trackReference: updatedFocusTrack,
        });
      }
    }
  }, [
    screenShareTracks
      .map(ref => `${ref.publication.trackSid}_${ref.publication.isSubscribed}`)
      .join(),
    focusTrack?.publication?.trackSid,
    tracks,
  ]);

  return (
    <div
      className={`lk-video-conference ${
        sideBarOpen ? 'side-bar-open' : ''
      } ${getWindowSizeClass(windowWidth)}`}
      {...props}
      ref={sizeRef}
    >
      {isWeb() && (
        <LayoutContextProvider
          onWidgetChange={widgetUpdate}
          value={layoutContext}
        >
          <WidgetManager widgetState={widgetState} />
          <div className="lk-video-conference-inner">
            {!focusTrack ? (
              <div className="lk-grid-layout-wrapper">
                <GridLayout tracks={tracks}>
                  <ParticipantTile />
                </GridLayout>
              </div>
            ) : (
              <div className="lk-focus-layout-wrapper">
                <FocusLayoutContainer>
                  <CarouselLayout tracks={carouselTracks}>
                    <ParticipantTile />
                  </CarouselLayout>
                  {focusTrack && <FocusLayout trackRef={focusTrack} />}
                </FocusLayoutContainer>
              </div>
            )}
            <ControlBar
              controls={{
                chat: !isPresentGo && chat,
                leave: !isPresentGo,
                settings: !!SettingsComponent,
                screenShare: screenshare,
                camera: video,
              }}
              variation={
                windowWidth < 1000 || sideBarOpen ? 'minimal' : undefined
              }
            />
          </div>
          <Chat
            messageDecoder={chatMessageDecoder}
            messageEncoder={chatMessageEncoder}
            messageFormatter={chatMessageFormatter}
            style={{
              display:
                widgetState.showChat && !widgetState.showSettings
                  ? 'grid'
                  : 'none',
            }}
          />
          {SettingsComponent && (
            <div
              className="lk-chat lk-settings"
              style={{ display: widgetState.showSettings ? 'block' : 'none' }}
            >
              <SettingsComponent
                hasBackgroundBlur={hasBackgroundBlur}
                setHasBackgroundBlur={setHasBackgroundBlur}
              />
            </div>
          )}
        </LayoutContextProvider>
      )}
      <RoomAudioRenderer />
      <ConnectionStateToast />
    </div>
  );
}

import { Track } from 'livekit-client';

export const calculateVideoWidths = (
  rightRatio: string,
  dimensions?: Track.Dimensions,
) => {
  // if we have ref dimensions, then return null
  if (!dimensions) {
    return {
      left: null,
      right: null,
    };
  }

  const aspectLeft = dimensions.width / dimensions.height;
  const aspectRight = rightRatio === '16_9' ? 16 / 9 : 1;

  const widthLeft = aspectLeft;
  const widthRight = aspectRight;
  const totalWidth = widthLeft + widthRight;

  const percentLeft = (widthLeft / totalWidth) * 100;
  const percentRight = (widthRight / totalWidth) * 100;

  return {
    left: percentLeft,
    right: percentRight,
  };
};
